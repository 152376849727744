import { defineStore } from "pinia";
import { ref } from "vue";
import type { Ref } from "vue";
import type { Notify } from "@/types";

export const useRootStore = defineStore("root", () => {
  const appLoading = ref(false);
  const downloadAppModalActive = ref(false);

  const notify: Ref<Notify> = ref({
    active: false,
    title: "",
    desc: "",
    borderColour: "",
    textColour: "",
    icon: "",
    duration: 0,
  });

  const toggleShowNotify = ({ active = true, title, desc, borderColour, textColour, icon, duration = 5000 }: Notify) => {
    notify.value = { active, title, desc, borderColour, textColour, icon };
    setTimeout(() => {
      notify.value.active = false;
    }, duration);
  };

  const toggleDownloadAppModal = (active: boolean) => {
    downloadAppModalActive.value = active;
  };

  return { notify, toggleShowNotify, appLoading, downloadAppModalActive, toggleDownloadAppModal };
});
