import type { H3Error } from "h3";

export default defineNuxtPlugin({
  name: "capture-error",
  dependsOn: ["sentry-client"],
  async setup(nuxtApp) {
    const { $sentryCaptureException } = useNuxtApp();

    nuxtApp.hook("vue:error", (err) => {
      $sentryCaptureException(err);
    });

    nuxtApp.hook("app:error", (err) => {
      $sentryCaptureException(err);
    });
  },
});
