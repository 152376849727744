export default {
  dashboard: () => import("/vercel/path0/layouts/dashboard.vue"),
  default: () => import("/vercel/path0/layouts/default.vue"),
  fullpage: () => import("/vercel/path0/layouts/fullpage.vue"),
  marketing: () => import("/vercel/path0/layouts/marketing.vue"),
  "partials-dashboard-desktop-side-bar": () => import("/vercel/path0/layouts/partials/dashboard/DesktopSideBar.vue"),
  "partials-dashboard-mobile-side-bar": () => import("/vercel/path0/layouts/partials/dashboard/MobileSideBar.vue"),
  "partials-dashboard-nav-bar": () => import("/vercel/path0/layouts/partials/dashboard/NavBar.vue"),
  "partials-dashboard-side-bar-item": () => import("/vercel/path0/layouts/partials/dashboard/SideBarItem.vue"),
  "partials-dashboard-onboarding-base-step": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/BaseStep.vue"),
  "partials-dashboard-onboarding-stepper-modal": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/OnboardingStepperModal.vue"),
  "partials-dashboard-onboarding-steps-free-credit": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/steps/FreeCredit.vue"),
  "partials-dashboard-onboarding-steps-intro-step": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/steps/IntroStep.vue"),
  "partials-dashboard-onboarding-steps-step-four": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/steps/StepFour.vue"),
  "partials-dashboard-onboarding-steps-step-one": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/steps/StepOne.vue"),
  "partials-dashboard-onboarding-steps-step-three": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/steps/StepThree.vue"),
  "partials-dashboard-onboarding-steps-step-two": () => import("/vercel/path0/layouts/partials/dashboard/onboarding/steps/StepTwo.vue"),
  "partials-marketing-app-logo": () => import("/vercel/path0/layouts/partials/marketing/AppLogo.vue"),
  "partials-marketing-desktop-nav": () => import("/vercel/path0/layouts/partials/marketing/DesktopNav.vue"),
  "partials-marketing-footer": () => import("/vercel/path0/layouts/partials/marketing/MarketingFooter.vue"),
  "partials-marketing-header": () => import("/vercel/path0/layouts/partials/marketing/MarketingHeader.vue"),
  "partials-marketing-mobile-nav": () => import("/vercel/path0/layouts/partials/marketing/MobileNav.vue"),
  "partials-marketing-nav-menu-item": () => import("/vercel/path0/layouts/partials/marketing/NavMenuItem.vue"),
  "partials-marketing-page-header": () => import("/vercel/path0/layouts/partials/marketing/PageHeader.vue")
}