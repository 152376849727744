<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

const handleError = (route: string) => clearError({ redirect: route });
</script>

<template>
  <NuxtLayout name="fullpage">
    <main v-if="error" class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-primary">{{ error.statusCode }}</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-dark-gray sm:text-5xl">
          {{ error.statusCode === 404 ? "Page not found" : "An error occurred" }}
        </h1>
        <p class="mt-6 text-base leading-7 text-medium-gray">
          {{
            error.statusCode === 404 ? "Sorry, we couldn't find the page you're looking for." : "Sorry, we couldn't process your request."
          }}
        </p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <PrimaryButton @click="handleError(ROUTES.home)">Go back home</PrimaryButton>

          <div @click="handleError(ROUTES.contact)" class="text-sm font-semibold text-dark-gray">
            Contact support <span aria-hidden="true">&rarr;</span>
          </div>
        </div>
      </div>
    </main>
  </NuxtLayout>
</template>
