import { defineNuxtPlugin, useRuntimeConfig } from "#app";
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
  name: "sentry-client",

  setup(nuxtApp) {
    const router = useRouter();
    const config = useRuntimeConfig();
    const {
      public: { sentry },
    } = useRuntimeConfig();

    if (!sentry.dsn) return;

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: config.public.env,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.captureConsoleIntegration({
          levels: ["error"],
        }),
      ],
      tracesSampleRate: parseFloat(sentry.tracesSampleRate),
      tracePropagationTargets: ["localhost", config.public.appUrl],
      replaysSessionSampleRate: parseFloat(sentry.replaysSessionSampleRate),
      replaysOnErrorSampleRate: parseFloat(sentry.replaysOnErrorSampleRate),
    });

    return {
      provide: {
        sentryCaptureException: Sentry.captureException,
      },
    };
  },
});
