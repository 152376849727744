<script setup lang="ts">
import CustomLoader from "@/components/CustomLoader.vue";
import type { H3Error } from "h3";
import "reflect-metadata";

const config = useRuntimeConfig();
const route = useRoute();

useHead({
  titleTemplate: "%s | Hirable",
});

useSeoMeta({
  author: () => config.public.appName,
  ogImage: `${config.public.appUrl}/hirable.jpg`,
  ogType: "website",
  ogSiteName: () => config.public.appName,
  ogUrl: () => `${config.public.appUrl}${route.path}`,
  twitterCard: "summary_large_image",
  twitterImage: `${config.public.appUrl}/hirable.jpg`,
  twitterImageAlt: () => config.public.appName,
  twitterSite: "@HirableSa",
  twitterCreator: "@HirableSa",
});

const displayError = (err: unknown) => {
  clearError();
  const error = (err as H3Error).statusMessage || (err as Error).message;
  showNotifyError(error);
};
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtErrorBoundary @error="displayError">
        <NuxtPage class="dark:text-white" />
      </NuxtErrorBoundary>
      <ScrollToTop />
    </NuxtLayout>
    <Notify />
    <CustomLoader />
  </div>
</template>
